<template>
  <transition name="fade" appear>
    <div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>意见与建议</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="content flex justify-center align-center">
        <el-form :model="ruleForm" status-icon label-width="100px" class="demo-ruleForm form">
          <el-form-item label="姓名：" prop="name">
            <el-input type="text" v-model="ruleForm.name" autocomplete="off" class="form-input"></el-input>
          </el-form-item>
          <el-form-item label="邮箱：" prop="email">
            <el-input type="text" v-model="ruleForm.email" autocomplete="off" class="form-input"></el-input>
          </el-form-item>
          <el-form-item label="电话：" prop="phone">
            <el-input v-model="ruleForm.phone" class="form-input"></el-input>
          </el-form-item>
          <el-form-item label="反馈内容：" prop="content">
            <el-input
              type="textarea"
              :rows="7"
              v-model="ruleForm.content"
              class="form-input-area"
              placeholder="请在这里写下您的建议..."
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <div class="btn" @click="submitForm('')">确认提交</div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      rules: {},
      ruleForm: {
        name: "",
        email: "",
        phone: "",
        content: "",
        memberCode: JSON.parse(sessionStorage.getItem("userInfo")).memberCode,
      },
    };
  },
  methods: {
    submitForm() {
      var param = {
        ...this.ruleForm,
      };
      this.$api.user.saveOpinion(param).then((res) => {
        if (res.data.code === 200) {
          this.$router.push("/opinion/end");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  margin-top: 30px;
  background: #f9fbfc;
  height: 598px;
  .form {
    text-align: left;
    .form-input {
      width: 325px;
    }
    .form-input-area {
      width: 530px;
    }
  }
}
/deep/ .el-form-item__label {
  font-size: 16px;
}
.btn {
  cursor: pointer;
  margin: 40px auto 0;
  color: #fff;
  width: 220px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  background: #db3b3d;
}
</style>
